import {createContext, ReactNode, useCallback, useContext, useState} from "react";
import {PopupModal} from "../common/modals/PopupModal"

type ModalValue = {
    openPopupModal: (modal: ReactNode) => void
    closePopupModal: () => void;
    isPopupShowing: boolean;
    popupModal: ReactNode | null;
}

const ModalContext = createContext<ModalValue>({
    openPopupModal: (modal: ReactNode) => new Promise(() => null),
    closePopupModal: () => new Promise(() => null),
    isPopupShowing: false,
    popupModal: null,
})

export const useModal = () => useContext(ModalContext)

export const ModalProvider = ({children}: {children: React.ReactNode}) => {
    const [isPopupShowing, setIsPopupShowing] = useState(false);
    const [popupModal, setPopupModal] = useState<ReactNode>();

    const openPopupModal = useCallback((modal: ReactNode) => {
        setIsPopupShowing(true)
        setPopupModal(<PopupModal>{modal}</PopupModal>)
    }, [])

    const closePopupModal = useCallback(() => {
        setIsPopupShowing(false)
    }, [])

    const value: ModalValue = {
        openPopupModal,
        closePopupModal,
        isPopupShowing,
        popupModal,
    }

    return (
        <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
    )
}


