import {Link} from "react-router-dom"

type Props = {
    setSelected: (selection: string) => void
    selected: string
}

const BottomNav: React.FC<Props> = ({setSelected, selected}: Props) => {
    //const contact = <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" /></svg>
    //const work = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"><path d="M380 976q-75 0-127.5-52.5T200 796q0-32 14.5-60.5T263 673q15-15 24-25t19-22q-51-78-78.5-163.5T200 296q0-58 21-89t59-31q42 0 87 41t79 109q10 20 18.5 41.5T480 415q7-26 15.5-47.5T514 326q34-68 79-109t87-41q38 0 59 31t21 89q0 81-27.5 166.5T654 626q10 12 19 22t24 25q34 34 48.5 62.5T760 796q0 75-52.5 127.5T580 976q-45 0-72.5-10L480 956l-27.5 10Q425 976 380 976Zm0-60q17 0 40-5.5t49-16.5q-11-5-20-17t-9-21q0-8 11.5-14t28.5-6q17 0 28.5 6t11.5 14q0 9-9 21t-20 17q26 11 49 16.5t40 5.5q50 0 85-35t35-85q0-22-12-43t-35-41q-11-10-24.5-24T604 660q-32-42-55-53t-69-11q-46 0-69 11t-55 53q-11 14-24.5 28T307 712q-23 20-35 41t-12 43q0 50 35 85t85 35Zm40-150q-8 0-14-9t-6-21q0-12 6-21t14-9q8 0 14 9t6 21q0 12-6 21t-14 9Zm120 0q-8 0-14-9t-6-21q0-12 6-21t14-9q8 0 14 9t6 21q0 12-6 21t-14 9ZM347 579q21-20 41.5-29.5T439 537q-5-51-17-99.5T391 351q-23-47-53-78.5T275 230q-8 15-11.5 32t-3.5 34q0 72 22.5 144.5T347 579Zm266 0q42-66 64.5-138.5T700 296q0-17-3.5-34T685 230q-33 11-63 42.5T569 351q-19 38-31 86.5T521 537q30 3 50.5 12.5T613 579Z" /></svg>
    //const object = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"><path d="m180 746 270 156V593L180 437v309Zm330 156 270-156V436L510 593v309ZM213 387l267 155 266-155-266-154-267 154Zm-93 394V406q0-16 8-30t22-22l300-173q14-8 30-8t30 8l300 173q14 8 22 22t8 30v340q0 16-8 30t-22 22L510 971q-14 8-30 8t-30-8L120 781Zm360-205Z" /></svg>
    // <div className="w-6 h-6">{object}</div>
    return (
        <ul className="flex justify-evenly w-full h-16 text-orange text-sm border-gray-400 border-t border-opacity-50 bg-white">
            <Link to="jewelry">
                <li onClick={() => setSelected("jewelry")} className={`${selected !== "jewelry" && "text-black"} h-full px-8 hover:text-orange`}>
                    <div className="flex flex-col items-center justify-center w-12 h-full" >
                        <p>Jewelry</p>
                    </div>
                </li>
            </Link>
            <Link to="objects">
                <li onClick={() => setSelected("objects")} className={`${selected !== "objects" && "text-black"} h-full px-8 hover:text-orange`}>
                    <div className="flex flex-col items-center justify-center w-12 h-full" >
                        <p>Objects</p>
                    </div>
                </li>
            </Link>
            <Link to="contact">
                <li onClick={() => setSelected("contact")} className={`${selected !== "contact" && "text-black"} h-full px-8 hover:text-orange`}>
                    <div className="flex flex-col items-center justify-center w-12 h-full" >
                        <p>Contact</p>
                    </div>
                </li>
            </Link>
        </ul>
    )
}

export default BottomNav