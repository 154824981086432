import {ReactNode} from 'react';
import ReactDOM from 'react-dom';

type Props = {
    modal: ReactNode
    isShowing: boolean
    container: HTMLElement | null
}

export const Modal = ({modal, isShowing, container}: Props) => {
    if (!container) return null;
    if (!isShowing) return null;
    return (ReactDOM.createPortal(
        <>
            {modal}
        </>, container))
}