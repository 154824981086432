import {doc, onSnapshot, setDoc, updateDoc} from "firebase/firestore";
import {ref, uploadBytes} from "firebase/storage";
import {httpsCallable} from "firebase/functions";
import React, {useContext, useEffect, useState} from "react";
import {ImageObj, Thumbnail} from "shared/types";
import {db, storage, functions} from "../firebase";
import {useAuth} from "./AuthContext";
import {TaskStatus} from "shared/consts";
import {useModal} from "./ModalContext";

type AppValue = {
    updateStorage: (website: {home: ImageObj, jewelry: ImageObj[], objects: ImageObj[], pending: ImageObj[]}) => Promise<void>;
    setDropdownsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setHomeImage: React.Dispatch<React.SetStateAction<ImageObj>>;
    setJewelryImages: React.Dispatch<React.SetStateAction<ImageObj[]>>;
    setObjectImages: React.Dispatch<React.SetStateAction<ImageObj[]>>;
    setPendingImages: React.Dispatch<React.SetStateAction<ImageObj[]>>;
    uploadImage: (file: Blob, imageName: string, thumbnail: string) => Promise<string>;
    deleteImage: (name: string) => Promise<string>;
    thumbnails: Thumbnail[];
    dropdownsVisible: boolean;
    jewelryImages: ImageObj[];
    objectImages: ImageObj[];
    pendingImages: ImageObj[];
    homeImage: ImageObj;
    isLoading: boolean;
}


const AppContext = React.createContext<AppValue>({
    updateStorage: (website: {home: ImageObj, jewelry: ImageObj[], objects: ImageObj[], pending: ImageObj[]}) => new Promise(() => ""),
    setHomeImage: () => null,
    setDropdownsVisible: () => null,
    setJewelryImages: () => null,
    setObjectImages: () => null,
    setPendingImages: () => null,
    uploadImage: (file: Blob, imageName: string, thumbnail: string) => new Promise(() => ""),
    deleteImage: (name: string) => new Promise(() => ""),
    dropdownsVisible: true,
    jewelryImages: [],
    objectImages: [],
    pendingImages: [],
    homeImage: {
        name: "",
        sources: [],
    },
    isLoading: false,
    thumbnails: [],
})

export const useApp = () => useContext(AppContext)

export const AppProvider = ({children}: {children: React.ReactNode}) => {
    const [isInit, setIsInit] = useState(true);
    const [jewelryImages, setJewelryImages] = useState<ImageObj[]>([]);
    const [objectImages, setObjectImages] = useState<ImageObj[]>([]);
    const [pendingImages, setPendingImages] = useState<ImageObj[]>([])
    const [homeImage, setHomeImage] = useState<ImageObj>({
        name: "",
        sources: []
    });
    const [thumbnails, setThumbnails] = useState<Thumbnail[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dropdownsVisible, setDropdownsVisible] = useState(true);
    const {currentUser} = useAuth();
    const {closePopupModal, openPopupModal} = useModal();

    useEffect(() => {
        if (!currentUser) return;
        let unsub: () => void
        unsub = onSnapshot(doc(db, "thumbnails", currentUser.uid), (doc) => {
            if (doc.exists()) {
                setThumbnails(doc.data().thumbnails)
            }
        })
        return () => {
            if (unsub) unsub()
        }
    }, [currentUser])

    useEffect(() => {
        let unsub: () => void
        unsub = onSnapshot(doc(db, "website", "imageData"), (doc) => {
            if (doc.exists()) {
                const obj = doc.data();
                setJewelryImages(obj.jewelryImages);
                setHomeImage(obj.homeImage);
                setObjectImages(obj.objectImages);
                setPendingImages(obj.pendingImages)
                setIsInit(false);
            }
        })
        return () => {
            if (unsub) unsub()
        }
    }, [thumbnails])



    useEffect(() => {
        async function refreshTaskStatus() {
            let user = currentUser?.uid;
            const tasksRef = doc(db, `tasks/${user}`)
            await updateDoc(tasksRef, {taskStatus: TaskStatus.IDLE})
        }
        let unsub: () => void
        if (!currentUser) return;
        unsub = onSnapshot(doc(db, "tasks", currentUser.uid), (doc) => {
            if (doc.exists()) {
                let taskStatus = doc.data().taskStatus;
                if (taskStatus === TaskStatus.IDLE) {
                    closePopupModal()
                } else if (taskStatus === TaskStatus.UPLOADING) {
                    openPopupModal(<div className="border shadow-2xl p-4 animate-pulse text-white">Uploading</div>)
                } else if (taskStatus === TaskStatus.GENERATING) {
                    openPopupModal(<div className="border shadow-2xl p-4 animate-pulse text-white">Generating</div>)
                } else if (taskStatus === TaskStatus.FAILED) {
                    openPopupModal(<div className="border shadow-2xl p-4 animate-pulse text-white">Failed: Duplicate Name</div>)
                    setTimeout(() => refreshTaskStatus(), 2000);
                }
                else if (taskStatus === TaskStatus.DELETING) {
                    openPopupModal(<div className="border shadow-2xl p-4 animate-pulse text-white">Deleting</div>)
                }
            }
        })
        return () => {
            if (unsub) unsub()
        }
    }, [closePopupModal, currentUser, openPopupModal])

    const uploadImage = async (file: Blob, imageName: string, thumbnail: string) => {
        if (!currentUser?.uid) return "failure";
        let user = currentUser?.uid;
        const imagesRef = ref(storage, `images/${imageName}`);
        let error = false;
        const tasksRef = doc(db, `tasks/${user}`)
        await updateDoc(tasksRef, {taskStatus: TaskStatus.UPLOADING, thumbnail})
        const metadata = {
            customMetadata: {
                currentUser: user,
            },
        };
        await uploadBytes(imagesRef, file, metadata).then(async (snapshot) => {
        }).catch(e => {
            error = true;
        });
        if (error) {
            await updateDoc(tasksRef, {taskStatus: TaskStatus.FAILED});
            return "failure";
        }
        return "success";
    }

    const deleteImage = async (name: string) => {
        if (!currentUser?.uid) return "failure";
        let user = currentUser?.uid;
        const tasksRef = doc(db, `tasks/${user}`)
        await updateDoc(tasksRef, {taskStatus: TaskStatus.DELETING})
        setIsLoading(true);
        let result;
        try {
            result = await httpsCallable(functions, 'deleteImage')({imageName: name})
        } catch (e) { }
        setIsLoading(false);
        return (result?.data === "success") ? "success" : "failure";
    }

    const updateStorage = async (website: {home: ImageObj, jewelry: ImageObj[], objects: ImageObj[], pending: ImageObj[]}) => {
        setIsLoading(true);
        const docRef = doc(db, "website/imageData")

        let pendingHolder = [...website.pending]
        let jewelryHolder = [...website.jewelry]
        let objectsHolder = [...website.objects]
        let homeHolder = website.home;
        pendingHolder.forEach((pending) => {
            delete pending.thumbnail
        })
        jewelryHolder.forEach((jewelry) => {
            delete jewelry.thumbnail
        })
        objectsHolder.forEach((object) => {
            delete object.thumbnail
        })
        delete homeHolder.thumbnail

        await setDoc(docRef, {
            jewelryImages: jewelryHolder,
            objectImages: objectsHolder,
            pendingImages: pendingHolder,
            homeImage: homeHolder,
        }).then(() => {
        }).catch(e => {
            console.log("e: ", e);
        });
        setIsLoading(false);
    }

    const value: AppValue = {
        setHomeImage, setJewelryImages, setPendingImages, setObjectImages, uploadImage, deleteImage,
        updateStorage, jewelryImages, objectImages, homeImage,
        setDropdownsVisible,
        dropdownsVisible,
        pendingImages,
        isLoading, thumbnails,
    }

    return (
        <AppContext.Provider value={value}>
            {!isInit && children}
        </AppContext.Provider>
    )
}
