import {useAuth} from "contexts/AuthContext"
import {Link} from "react-router-dom"

type Props = {
    setSelected: (selection: string) => void
    selected: string
}

const TopNav: React.FC<Props> = ({setSelected, selected}: Props) => {
    const {currentUser, logOut} = useAuth();
    return (
        <nav className="flex gap-20 xl:gap-40 md:justify-start md:pl-6 justify-center h-16 border-gray-400 border-b border-opacity-30">
            <span onClick={() => setSelected("none")} className="flex justify-center items-center md:items-end text-2xl font-thin text-orange whitespace-nowrap" style={{letterSpacing: "6px"}}>
                <Link to="/">MIRA KIM</Link>
            </span>
            <ul className="hidden md:flex md:gap-20 xl:gap-40 items-end">
                <li onClick={() => setSelected("jewelry")} className={selected === "jewelry" ? "text-orange" : "hover:text-orange"}><Link to="jewelry">Jewelry</Link></li>
                <li onClick={() => setSelected("objects")} className={selected === "objects" ? "text-orange" : "hover:text-orange"}><Link to="objects">Objects</Link></li>
                <li onClick={() => setSelected("contact")} className={selected === "contact" ? "text-orange" : "hover:text-orange"}><Link to="contact">Contact</Link></li>
            </ul>
            {currentUser?.uid && <div className="flex w-full p-2 pr-12 items-end justify-end ">
                <button onClick={logOut} className=" hover:opacity-50 border-2 border-black p-0.5 w-8 h-8 rounded-lg disabled:opacity-75" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"><path d="M180 936q-24 0-42-18t-18-42V276q0-24 18-42t42-18h291v60H180v600h291v60H180Zm486-185-43-43 102-102H375v-60h348L621 444l43-43 176 176-174 174Z" /></svg>
                </button>
            </div>}
        </nav>
    )
}

export default TopNav