import {ReactNode} from "react"
import {useModal} from "../../contexts/ModalContext"

interface Props {
    children: ReactNode
    className?: string
}

export const PopupModal = (({children, className}: Props) => {
    const {closePopupModal} = useModal()
    return (
        <>
            <div className="fixed top-0 left-0 bottom-0 right-0 flex justify-center items-center bg-black opacity-80 z-10" />
            <div onClick={closePopupModal} className="fixed flex justify-center items-center top-0 left-0 w-full h-full z-20">
                {children}
            </div>
        </>
    )
})