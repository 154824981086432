import {ImageObj} from "shared/types";
import {useApp} from "../../contexts/AppContext";
import HomeThumbnail from "./HomeThumbnail";
import {ImageUploader} from "./ImageUploader";
import PageThumbnail from "./PageThumbnail";
import PendingThumbnail from "./PendingThumbnail";
import {Dropdown} from "common/Dropdown";
import {Modal} from "common/modals/Modal";
import {useModal} from "contexts/ModalContext";

export default function Admin() {
    const {homeImage, jewelryImages, objectImages, pendingImages, updateStorage, deleteImage} = useApp()
    const {isPopupShowing, popupModal} = useModal();



    /*
    //home image14.jpg
    //project21 1.jpg,2.jpg,3.jpg,4.jpg,5.jpg,6.jpg,7.jpg,8.jpg,9.jpg,10.jpg,11.jpg,12.jpg,13.jpg,14.jpg,15.jpg,16.jpg,17.jpg,18.jpg
    //Time: Through time, the interaction of things creates new relationships. Materials break down and transform. A transformation can take thousands of years or last a few seconds. Where does jewelry fit into this? The combinations of persistence and impact, materials and information, the characteristics of the environment in which the interaction occurs, each affects the others and the relationships they create are unique and different.
    // ["",
    // "",
    // "image80.jpeg,image81.jpeg,image36.jpeg,image37.jpeg,image38.jpeg, image39.jpeg, image40.jpeg,image33.jpg, image34.png", 
    // "image14.jpg,image16.jpeg,image17.jpeg,image18.jpg,image19.jpg,image20.jpeg,image21.jpg,image22.jpeg,image23.jpg,image24.jpg,image25.jpg,image26.jpg,image27.jpg,image28.jpg,image29.jpeg,image30.jpg,image31.jpg,image32.jpg", 
    // "",
    // "",
    // "image77.jpeg,image66.jpeg,image67.jpeg,image68.jpeg,image69.jpeg,image70.jpeg,image71.jpeg,image72.jpeg,image73.jpeg,image74.jpeg,image75.jpeg,image76.jpeg,image78.jpeg,image79.jpeg",
    // "",
    // "image47.jpeg,image48.jpeg,image49.jpeg,image50.jpeg,image51.jpeg,image52.jpeg,image53.jpeg,image54.jpeg,image55.jpeg,image56.jpeg,image57.jpeg,image58.jpeg,image59.jpeg,image60.jpeg,image61.jpeg,image62.jpeg,image63.jpeg,image64.jpeg,image65.jpeg",
    // "",
    // "",
    // "image46.jpeg, image43.jpeg,image44.jpeg,image45.jpeg",
    // "image2.png,image3.jpeg,image4.jpeg,image5.jpeg,image6.jpeg,image8.jpeg,image9.jpeg,image10.jpeg,image11.jpeg,image12.jpeg,image13.jpeg"]
 */

    const handleHomeSelect = async (index: number) => {
        let holder = [...pendingImages];
        holder.splice(index, 1);
        if (homeImage.name?.length) holder.unshift(homeImage)

        await updateStorage({
            jewelry: jewelryImages,
            objects: objectImages,
            pending: holder,
            home: pendingImages[index]
        })
    }

    const handlePageRemove = async (myIndex: number, pageType: string) => {
        let holder = [...pendingImages];
        if (pageType === "jewelry") {
            if (jewelryImages[myIndex].name.length === 0 && jewelryImages.length === 1) return;
            let holder2 = [...jewelryImages]
            holder2.splice(myIndex, 1)
            await updateStorage({
                jewelry: holder2,
                objects: objectImages,
                pending: [jewelryImages[myIndex], ...holder],
                home: homeImage
            })

        } else if (pageType === "object") {
            if (objectImages[myIndex].name.length === 0 && objectImages.length === 1) return;
            let holder2 = [...objectImages]
            holder2.splice(myIndex, 1)
            await updateStorage({
                jewelry: jewelryImages,
                objects: holder2,
                pending: [objectImages[myIndex], ...holder],
                home: homeImage
            })
        }
    }

    const handlePageSelect = async (myIndex: number, pendingIndex: number, pageType: string, isEmpty: boolean) => {
        let pendingHolder = [...pendingImages];
        let jewelryHolder = [...jewelryImages];
        let objectsHolder = [...objectImages];
        if (pageType === "jewelry") {
            if (isEmpty) {
                jewelryHolder.splice(myIndex + 1, 0, pendingImages[pendingIndex])
                pendingHolder.splice(pendingIndex, 1);
            } else {
                pendingHolder[pendingIndex] = jewelryImages[myIndex];
                jewelryHolder[myIndex] = pendingImages[pendingIndex];
            }
        } else if (pageType === "object") {
            if (isEmpty) {
                objectsHolder.splice(myIndex + 1, 0, pendingImages[pendingIndex])
                pendingHolder.splice(pendingIndex, 1);
            } else {
                pendingHolder[pendingIndex] = objectsHolder[myIndex];
                objectsHolder[myIndex] = pendingImages[pendingIndex];
            }
        }
        await updateStorage({
            jewelry: jewelryHolder,
            objects: objectsHolder,
            pending: pendingHolder,
            home: homeImage
        })
    }

    async function handleDelete(myIndex: number, image: ImageObj) {
        const result = await deleteImage(image.name)
        if (result === "success") {
            let holder = [...pendingImages];
            holder.splice(myIndex, 1);
            await updateStorage({
                jewelry: jewelryImages,
                objects: objectImages,
                pending: holder,
                home: homeImage
            })
        }

    }

    function handleEmptyJewelryImages(pendingIndex: number) {
        handlePageSelect(jewelryImages.length, pendingIndex, "jewelry", true);
    }
    function handleEmptyObjectImages(pendingIndex: number) {
        handlePageSelect(objectImages.length, pendingIndex, "object", true);
    }

    return (
        <div className="flex flex-col h-full w-full p-4" >

            <div className="flex gap-2 justify-center p-2">
                <div className="flex flex-col gap-2 items-center border">
                    <ImageUploader pendingHolder={pendingImages} />
                    <div className="w-64 h-80 p-2  border border-gray-400 shadow-lg">
                        <p className="text-lg">Pending Images</p>
                        <div className="border max-h-64 overflow-y-auto">
                            {
                                pendingImages.map((imageObj, i) => {
                                    return <PendingThumbnail key={"pending" + i} myIndex={i} imageObj={imageObj} handleDelete={handleDelete} />
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="w-80 h-full p-2  border border-gray-400 shadow-lg relative">
                    <p className="text-lg">Home</p>
                    <HomeThumbnail imageObj={homeImage} options={pendingImages} selected={homeImage.name} selectImage={handleHomeSelect} />
                </div>
                <div className="w-80 h-full p-2 border border-gray-400 shadow-lg relative">
                    <p className="text-lg">Jewelry</p>
                    <div className="absolute left-0 right-0 mx-2 overflow-y-auto h-5/6" style={{height: "91%"}}>
                        {

                            jewelryImages.map((jewelry: ImageObj, i: number) => {
                                return <PageThumbnail key={i} imageObj={jewelry} options={pendingImages} selected={jewelry.name} selectImage={handlePageSelect}
                                    myIndex={i} isLast={jewelryImages.length === i + 1} pageType="jewelry" removeThumbnail={handlePageRemove} />
                            })
                        }
                        {
                            <div className="relative flex items-center justify-center bg-white w-full h-12 p-2 border-gray-400 border mb-0.5">
                                <Dropdown options={pendingImages} selected={""} selectImage={handleEmptyJewelryImages} />
                            </div>
                        }
                    </div>
                </div>
                <div className="w-80 h-full p-2 border border-gray-400 shadow-lg relative">
                    <p className="text-lg">Objects</p>
                    <div className="absolute left-0 right-0 mx-2 overflow-y-auto h-5/6" style={{height: "91%"}}>
                        {
                            objectImages.map((object: ImageObj, i: number) => {
                                return <PageThumbnail key={i} imageObj={object} options={pendingImages} selected={object.name} selectImage={handlePageSelect}
                                    myIndex={i} isLast={objectImages.length === i + 1} pageType="object" removeThumbnail={handlePageRemove} />
                            })
                        }
                        {
                            <div className="relative flex items-center justify-center bg-white w-full h-12 p-2 border-gray-400 border mb-0.5">
                                <Dropdown options={pendingImages} selected={""} selectImage={handleEmptyObjectImages} />
                            </div>
                        }
                    </div>
                </div>
                <Modal
                    isShowing={isPopupShowing}
                    modal={popupModal}
                    container={document.getElementById("popup-root")}
                />
            </div>
        </div>
    );
}

