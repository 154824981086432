import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";

type Props = {
    requiredRole?: string
}
export const RequireAuth = ({requiredRole}: Props) => {
    let {currentUser} = useAuth();
    let location = useLocation();
    let pathname = location.pathname
    if (currentUser === null || currentUser === undefined) {
        return <Navigate to="/login" state={{from: pathname}} replace />
    }
    return <Outlet />;
}
