import {Dropdown} from "common/Dropdown";
import {useApp} from "contexts/AppContext";
import {ImageObj} from "shared/types";

type Props = {
    imageObj: ImageObj;
    options: ImageObj[];
    selectImage: (index: number) => void;
    selected: string;
}

export default function HomeThumbnail({imageObj, options, selected, selectImage}: Props) {
    const {thumbnails} = useApp();
    let src;
    for (let i = 0; i < thumbnails.length; i++) {
        if (imageObj.name === thumbnails[i].name) {
            src = thumbnails[i].thumbnail;
            break;
        }
    }
    return (
        <>{imageObj.name?.length ?
            <>
                <div className="relative flex flex-col items-center gap-4 w-full h-40 p-2 bg-white border-gray-400 border mb-0.5">
                    <img className={"object-scale-down h-24 w-24"} src={src} alt={imageObj.name}></img>
                    <Dropdown options={options} selected={selected} selectImage={selectImage} />
                </div>
            </>
            :
            <div className="relative flex justify-end w-full h-24 p-2 bg-white border-gray-400 border mb-0.5">
                <Dropdown options={options} selected={selected} selectImage={selectImage} />
            </div>
        }</>
    );
}
