import ResponsiveImage from "common/ResponsiveImage";
import {useApp} from "../contexts/AppContext";
import 'firebase/storage';



export default function Home() {
  const {homeImage} = useApp()
  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <div className="flex w-full sm:w-3/5 md:w-3/5 max-w-2xl">
        <ResponsiveImage className={"w-full object-contain"} imageSources={homeImage} />
      </div>
    </div>
  );
}
