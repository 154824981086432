import {useApp} from "contexts/AppContext";
import React, {useEffect, useRef, useState} from "react";
import {ImageObj} from "shared/types";

type Props = {
    pendingHolder: ImageObj[]
}

export const ImageUploader = ({pendingHolder}: Props) => {
    const imageRef = useRef<HTMLImageElement>(null);
    const {uploadImage} = useApp();
    const [selectedImage, setSelectedImage] = useState<File | null | undefined>(null);
    const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
    const [name, setName] = useState<string>("");
    const [error, setError] = useState("");
    const fileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        async function wait() {
            await new Promise(() => {
                setTimeout(() => {
                    setError("");
                }, 2000)
            })
        }
        if (error) {
            wait();
        }
    }, [error])

    useEffect(() => {
        if (!selectedImage) return;
        if (imageRef.current) {
            const image = imageRef.current;
            image.onload = () => {
                const holder = createThumbnail(image, 128, 128);
                setThumbnailUrl(holder);
            };
        }
    }, [selectedImage]);

    function createThumbnail(image: HTMLImageElement, maxWidth: number, maxHeight: number): string {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d")!;
        let width = image.width;
        let height = image.height;
        if (width > height) {
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
        } else if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height);
        return canvas.toDataURL("image/jpeg");
    }

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedImage(file);
            const [name] = file.name.split(".");
            setName(name)
        }
    };

    async function handleUpload() {
        if (!selectedImage || !thumbnailUrl) return;
        // const imageObj: ImageObj = {
        //     name: name + fileType,
        //     thumbnail: "",
        //     sources: [],
        // }
        const result = await uploadImage(selectedImage, name, thumbnailUrl)
        if (result === "failure") {
            setError("*Image name already taken.");
            return;
        }
        //setPendingHolder([...pendingHolder, imageObj])
        fileRef.current!.value = "";
        imageRef.current!.src = "";
        setSelectedImage(null)
        setThumbnailUrl(null)
        setName("")
    }

    return <div className="flex flex-col gap-2 w-64 p-2  border border-gray-400 shadow-lg">
        <p className="text-lg w-full">Upload Image</p>
        <div className="flex justify-center items-center w-32 h-32 bg-gray-100 border border-gray-400">{(thumbnailUrl !== null) && <img src={thumbnailUrl} alt="Thumbnail" />}</div>
        <img ref={imageRef} className="hidden" src={selectedImage ? URL.createObjectURL(selectedImage!) : ""} alt="" />
        <input ref={fileRef} type="file" onChange={(e) => handleImageChange(e)} />
        <div className="flex w-full gap-2">
            <label className="flex">
                <p className="pr-1">Name</p>
                <input disabled={!selectedImage} className="border px-2 w-40" onChange={(e) => setName(e.target.value)} value={name} type="text" />
            </label>
            <button onClick={handleUpload} disabled={!selectedImage} className="w-6 h-6 border border-black bg-yellow-100 disabled:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
                    <path d="M452 854h60V653l82 82 42-42-156-152-154 154 42 42 84-84v201ZM220 976q-24 0-42-18t-18-42V236q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554V236H220v680h520V422H551ZM220 236v186-186 680-680Z" />
                </svg>
            </button>
        </div>
        {error ? <p className="text-orange h-4 text-sm">{error}</p> : <div className="h-4"></div>}

    </div>
}