import {useApp} from "contexts/AppContext";
import {useEffect, useState} from "react";
import {ImageObj} from "shared/types";

interface Props {
    options: ImageObj[];
    selectImage: (index: number) => void;
    selected: string;
    className?: string;
}

export function Dropdown({selectImage, options, selected, className = ""}: Props) {
    const [active, setActive] = useState<boolean>(false);
    const [textOptions, setTextOptions] = useState<string[]>([]);
    const {dropdownsVisible, setDropdownsVisible} = useApp();
    const [selectedText, setSelectedText] = useState("");

    useEffect(() => {
        if (!dropdownsVisible) {
            setActive(false);
        }
    }, [dropdownsVisible])

    async function handleSelect(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
        e.stopPropagation();
        selectImage(index);
        setActive(false)
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        if (!active) setDropdownsVisible(true);
        setActive(!active)
    }

    useEffect(() => {
        let holder: string[] = [];
        options.forEach((option) => {
            let name = option.name;
            if (name.length > 13) {
                holder.push(name.slice(0, 11) + "..");
            } else {
                holder.push(name)
            }
        })
        setTextOptions(holder);
    }, [options])

    useEffect(() => {
        let holder: string = selected;
        if (holder?.length > 13) {
            holder = holder.slice(0, 11);
            holder = holder + ".."
        }
        setSelectedText(holder);
    }, [selected])


    return (
        <div className="">
            <button type="button" className={`w-32 text-sm border-black bg-white border rounded-md ${className}`} onClick={(e) => handleClick(e)}>
                <div className="flex justify-between pl-2">
                    <div>{selectedText}</div>
                    <div className="w-6 h-6">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
                            <path d="M480 696 280 497h400L480 696Z" />
                        </svg>
                    </div>
                </div>

            </button>
            {(active === true && options.length > 0) && <div className={`flex flex-col gap-2 absolute z-10 right-0 h-fit min-h-8 w-32 p-2 bg-white border-black border rounded-md ${className} text-sm`}>
                {options.map((option, i) => {
                    return <button key={"options" + i} className={`flex flex-col justify-center pl-1 w-full h-8 items-start hover:opacity-50`}
                        onClick={(e) => handleSelect(e, i)}>
                        <p>{textOptions[i]}</p>
                    </button>
                })}
            </div>}
        </div>
    )
}
