import {useApp} from "contexts/AppContext";
import {useEffect, useState} from "react";
import {ImageObj} from "shared/types";

type Props = {
    imageObj: ImageObj;
    myIndex: number;
    handleDelete: (myIndex: number, image: ImageObj) => Promise<void>
}

export default function PendingThumbnail({imageObj, handleDelete, myIndex}: Props) {
    const name = imageObj.name;
    const {thumbnails} = useApp();

    const [truncatedName, setTruncatedName] = useState("");
    let src;
    for (let i = 0; i < thumbnails.length; i++) {
        if (imageObj.name === thumbnails[i].name) {
            src = thumbnails[i].thumbnail;
            break;
        }
    }
    useEffect(() => {
        if (name.length > 13) {
            let holder = name.slice(0, 11);
            setTruncatedName(holder + "..")
        } else {
            setTruncatedName(name)
        }
    }, [name])

    return (
        <div className="relative flex flex-col items-center gap-4 w-full h-40 p-2 bg-white border-gray-400 border mb-0.5">
            <img className={"object-scale-down h-24 w-24 flex-none"} src={src} alt={imageObj.name}></img>
            <p className="text-sm overflow-none whitespace-nowrap">{truncatedName}</p>
            <button onClick={() => handleDelete(myIndex, imageObj)} className="absolute top-0 right-0 m-1 w-6 h-6">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
                    <path d="M261 936q-24.75 0-42.375-17.625T201 876V306h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438V306ZM367 790h60V391h-60v399Zm166 0h60V391h-60v399ZM261 306v570-570Z" />
                </svg>
            </button>
        </div>
    );
}

