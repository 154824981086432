import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import {AppProvider} from 'contexts/AppContext';
import {AuthProvider} from 'contexts/AuthContext';
import {ModalProvider} from 'contexts/ModalContext';

ReactDOM.render(
  <React.StrictMode>
    <ModalProvider>
      <AuthProvider>
        <AppProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppProvider>
      </AuthProvider>
    </ModalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
