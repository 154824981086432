import {useState} from "react"
import {AUTH_SUCCESS, useAuth} from "../../contexts/AuthContext"
import {Link, useLocation} from "react-router-dom"

export const PasswordRecovery = () => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const {passwordReset} = useAuth()
    const [error, setError] = useState("")
    const [sent, setSent] = useState("")
    let location: any = useLocation();
    let from = location.state?.from || "/";

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setError("")
        setSent("")
        if (email.length === 0) {
            setError("Please fill in your email.")
            return
        }

        setLoading(true)
        const result = await passwordReset(email)
        if (result === AUTH_SUCCESS) {
            setSent('Password reset sent! Please check your email.')
        } else {
            setError(result)
            setLoading(false)
        }
    }

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setError("")
    }

    return (
        <div className="flex flex-col gap-4 p-2">
            <h1 className="font-bold text-xl w-full">Password Recovery</h1>
            <div className="flex justify-start w-full">
                <p> Enter your email to request a new password. </p>
            </div>
            <form className="flex flex-col items-start gap-2" onSubmit={handleSubmit}>
                <div className="w-full">
                    <label className="flex w-full">
                        <p className="w-24">Email</p>
                        <input className="border w-full md:w-64" onChange={(e) => handleEmail(e)} value={email} type="email" placeholder="Email" />
                    </label>
                </div>

                <div className="flex justify-between w-full">
                    {error.length > 0 &&
                        <div className="flex justify-start w-full">
                            <svg className="w-5 h-5 fill-red-600" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-4h-2V7h2v6z" opacity=".3" />
                                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-1-5h2v2h-2zm0-8h2v6h-2z" />
                            </svg>
                            <div className="ml-1">{error}</div>
                        </div>
                    }
                    {
                        (sent && !error) &&
                        <div className="flex justify-start w-full">
                            <svg className="w-5 h-5 fill-green-600" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm-2 13l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" opacity=".3" />
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
                            </svg>
                            <p className="ml-1">Message Sent.</p>
                        </div>
                    }
                </div>
                <button className="border rounded-sm px-4 border-orange bg-red-200 hover:bg-red-400" type="submit" disabled={loading}>Request Password</button>
            </form>
            <div className="flex flex-col w-full gap-4">
                <p className="text-base">Return to
                    <Link to="/login" state={{from: from}} replace className="text-blue-600 ml-1 text-base">login</Link>.
                </p>
            </div>
        </div>
    )
}