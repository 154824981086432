import {Dropdown} from "common/Dropdown";
import {useApp} from "contexts/AppContext";
import {useState} from "react";
import {ImageObj} from "shared/types";

type Props = {
    imageObj: ImageObj;
    myIndex: number;
    options: ImageObj[];
    selectImage: (myIndex: number, pendingIndex: number, pageType: string, isEmpty: boolean) => void;
    selected: string;
    pageType: string;
    isLast: boolean;
    removeThumbnail: (myIndex: number, pageType: string) => void;
}

export default function PageThumbnail({imageObj, options, selected, selectImage, myIndex, pageType, removeThumbnail, isLast}: Props) {
    const [emptyVisible, setEmptyVisible] = useState(false)
    const {thumbnails} = useApp();
    let src;
    for (let i = 0; i < thumbnails.length; i++) {
        if (imageObj.name === thumbnails[i].name) {
            src = thumbnails[i].thumbnail;
            break;
        }
    }
    function handleMyIndex(pendingIndex: number) {
        selectImage(myIndex, pendingIndex, pageType, false);
    }

    function handleEmptyIndex(pendingIndex: number) {
        setEmptyVisible(false);
        selectImage(myIndex, pendingIndex, pageType, true);
    }

    return (
        <div>
            <div className="relative flex flex-col items-center gap-4 w-full h-40 p-2 bg-white border-gray-400 border mb-0.5">
                <div className="absolute top-0 right-0 p-1">
                    {<button className="w-6 h-6" onClick={() => removeThumbnail(myIndex, pageType)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"><path d="m571 976-43-43 114-113-114-113 43-43 113 114 113-114 43 43-114 113 114 113-43 43-113-114-113 114ZM120 726v-60h300v60H120Zm0-165v-60h470v60H120Zm0-165v-60h470v60H120Z" /></svg>
                    </button>}
                </div>
                <img className={"object-scale-down h-24 w-24"} src={src} alt={imageObj.name}></img>
                <Dropdown options={options} selected={selected} selectImage={handleMyIndex} />
            </div>
            {!isLast && <>
                {emptyVisible ? <div className="relative flex items-center justify-between bg-white w-full h-12 p-2 border-gray-400 border mb-0.5">
                    <button onClick={() => {setEmptyVisible(false)}} className="h-5 w-5"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" ><path d="m448 730 112-112 112 112 43-43-113-111 111-111-43-43-110 112-112-112-43 43 113 111-113 111 43 43ZM120 576l169-239q13-18 31-29.5t40-11.5h420q24.75 0 42.375 17.625T840 356v440q0 24.75-17.625 42.375T780 856H360q-22 0-40-11.5T289 815L120 576Zm75 0 154 220h431V356H349L195 576Zm585 0V356v440-220Z" /></svg></button>
                    <Dropdown options={options} selected={""} selectImage={handleEmptyIndex} />
                </div> :
                    <div className={"flex w-full h-6"}><button onClick={() => {setEmptyVisible(true)}} className={"h-6 w-6 p-1"}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"> <path d="M450 776h60V606h170v-60H510V376h-60v170H280v60h170v170ZM180 936q-24 0-42-18t-18-42V276q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600V276H180v600Zm0-600v600-600Z" /></svg>
                    </button></div>
                }
            </>}
        </div>
    );
}
