import React from 'react';

const Contact: React.FC = () => {
    return (
        <div className="flex flex-col gap-4 w-full p-6">
            <p className='font-bold'>Contact</p>
            <div>
                <p>Email</p>
                <p>kimmira.mrk@gmail.com</p>
            </div>
            <div>
                <p>Instagram </p>
                <p>@kimmira.mrk</p>
            </div>
        </div>
    );
}

export default Contact