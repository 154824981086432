import {ImageObj} from "shared/types";

type Props = {
    imageSources: ImageObj;
    className?: string;
}

export const ResponsiveImage = ({imageSources, className = ""}: Props) => {
    if (imageSources.sources.length === 0) {
        return <></>
    }
    return (
        <picture>
            <source
                srcSet={`${imageSources.sources[0].srcSetWebp} 300w, ${imageSources.sources[1].srcSetWebp} 768w, ${imageSources.sources[2].srcSetWebp} 1280w`}
                sizes={`${imageSources.sources[0].size} ${imageSources.sources[1].size} ${imageSources.sources[2].size}`}
            />
            <source
                srcSet={`${imageSources.sources[0].srcSetJpeg} 300w, ${imageSources.sources[1].srcSetJpeg} 768w, ${imageSources.sources[2].srcSetJpeg} 1280w`}
                sizes={`${imageSources.sources[0].size} ${imageSources.sources[1].size} ${imageSources.sources[2].size}`}
            />

            <img loading="lazy" className={className} src={imageSources.sources[0].srcSetJpeg} alt={""} />
        </picture>
    );
};

export default ResponsiveImage;