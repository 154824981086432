import {useState} from "react"
import {AUTH_SUCCESS, useAuth} from "../../contexts/AuthContext"
import {Link, useLocation, useNavigate} from 'react-router-dom';

export const Login = () => {
    let navigate = useNavigate();
    let location: any = useLocation();
    let from = location.state?.from || "/";

    const {signIn} = useAuth()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault()
        if (email.length === 0 || password.length === 0) {
            setError("Please fill in all fields.")
            return
        }
        console.log("1")
        const result = await signIn(email, password);
        if (result === AUTH_SUCCESS) {
            console.log("???dfadfddasdfsadfasdfsadfdfsdf")

            navigate(from, {replace: true});
        } else {
            console.log("???f")

            setError(result)
        }
    }

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setError("")
    }

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setError("")
    }

    return (
        <div className="flex flex-col gap-4 p-2">
            <h1 className="font-bold text-xl w-full">Admin</h1>
            <form className="flex flex-col items-start gap-2" onSubmit={handleSubmit}>
                <div className="w-full">
                    <label className="flex w-full">
                        <p className="w-24">Email</p>
                        <input className="border w-full md:w-64" onChange={(e) => handleEmail(e)} value={email} type="email" placeholder="Email" />
                    </label>
                </div>
                <div className="w-full">
                    <label className="flex w-full">
                        <p className="w-24">Password</p>
                        <input className="border w-full md:w-64" onChange={(e) => handlePassword(e)} value={password} type="password" placeholder="Password" />
                    </label>
                </div>
                {error.length > 0 &&
                    <div className="flex justify-start w-full">
                        <svg className="w-5 h-5 fill-red-600" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-4h-2V7h2v6z" opacity=".3" />
                            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-1-5h2v2h-2zm0-8h2v6h-2z" />
                        </svg>
                        <div className="ml-1">{error}</div>
                    </div>
                }
                <button className="border rounded-sm px-4 border-orange bg-red-200 hover:bg-red-400" type="submit">Log In</button>
            </form>
            <p className="text-base">Forgot your
                <Link to="/recovery" state={{from: from}} replace className="text-blue-600 ml-2 text-base">password?</Link>
            </p>
        </div>
    )
}