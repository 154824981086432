import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from "firebase/storage";
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions'
const firebaseConfig = {
  apiKey: "AIzaSyCSLEApRuTDsx16y96tZ3FDjErorkd7p_g",
  authDomain: "kimmira-78366.firebaseapp.com",
  databaseURL: "https://kimmira-78366.firebaseio.com",
  projectId: "kimmira-78366",
  storageBucket: "kimmira-78366.appspot.com",
  messagingSenderId: "349583399020",
  appId: "1:349583399020:web:45975ca37d31fe38142049",
  measurementId: "G-C0RG3LD9EL"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const storage = getStorage();
export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp);
//connectFunctionsEmulator(functions, "localhost", 5001);
