import ResponsiveImage from "common/ResponsiveImage";
import {useApp} from "../contexts/AppContext";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export default function Objects() {
    const {objectImages} = useApp()

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="flex flex-col items-center">
            {
                objectImages?.map((imageObj, i) => {
                    return <div key={i} className="flex w-full sm:w-3/5 md:w-1/2 max-w-2xl h-5/6 m-24 sm:m-32 md:m-28">
                        <ResponsiveImage key={i} className={"flex-none mx-auto h-full object-contain"} imageSources={imageObj} />
                    </div>
                })
            }
        </div>
    );
}