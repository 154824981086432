import React, {useState} from 'react';
import {
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import Home from "./pages/Home"
import Contact from "./pages/Contact"
import Admin from "./pages/admin/Admin"
import TopNav from './common/TopNav';
import BottomNav from './common/BottomNav';
import {NoMatch} from "./pages/NoMatch"
import {RequireAuth} from 'common/guard/RequireAuth';
import {Login} from 'pages/admin/Login';
import {RequireNoAuth} from 'common/guard/RequireNoAuth';
import {PasswordRecovery} from 'pages/admin/PasswordRecovery';
import {useApp} from 'contexts/AppContext';
import Jewelry from 'pages/Jewelry';
import Objects from 'pages/Objects';


function App() {
  const [selected, setSelected] = useState<string>(useLocation().pathname.substring(1) ?? "none")

  const {setDropdownsVisible} = useApp();




  return (
    <div className="h-full w-full bg-white" onClick={() => setDropdownsVisible(false)}>
      <header className="fixed w-full z-10 bg-white">
        {<TopNav selected={selected} setSelected={setSelected} />}
      </header>
      <div className="absolute top-16 w-full bottom-16">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route element={<RequireNoAuth />}>
            <Route path="/login" element={<Login />} />
            <Route path="/recovery" element={<PasswordRecovery />} />
          </Route>
          <Route path="/objects" element={<Objects />} />
          <Route path="/jewelry" element={<Jewelry />} />
          <Route path="/contact" element={<Contact />} />
          <Route element={<RequireAuth />}>
            <Route path="/admin" element={<Admin />} />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
      <div className="fixed bottom-0 md:hidden w-full bg-white">
        {<BottomNav selected={selected} setSelected={setSelected} />}
      </div>
    </div>
  );
}

export default App;
